$('.bxslider').bxSlider({
    //mode: 'fade',
    captions: true,
    //controls: false,
    //autoControls: true,
    slideWidth: 980,
    speed: 900,
    pause: 20000,
    //randomStart: true,
    // auto: true,
    useCSS: true,

    minSlides: 2,
    maxSlides: 2,
    //adaptiveHeight: true,
    slideMargin: 10
});